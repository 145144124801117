<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex justify-center py-3 logo-container w-full">
      <img
        :src="require('../../../../../assets/images/fcLOgo.svg')"
        alt="Fresh Clinics"
        width="251"
        height="56"
      />
    </div>
    <div class="w-5/6 sm:w-1/2">
      <div class="py-5">
        <h1 class="flex sm:justify-center justify-left">Your Workspaces</h1>
        <div
          class="flex sm:justify-center justify-left"
          style="font-size: 14px"
        >
          Access and manage all of your Fresh Clinics workspaces.
        </div>
      </div>
      <div class="org-content">
        <ul id="example-1">
          <li
            v-for="(item, index) in organizations"
            :key="item.message"
            class="org-list flex items-center"
          >
            <div
              class="manage-modal"
              v-if="index == itemsFlag"
              @click="openItemsModal(-1)"
            />
            <div class="px-5 w-full">
              <div
                slot="header"
                class="flex justify-between items-center"
                style="position: relative"
              >
                <h4 class="org-title" @click="orgChanged(item)">
                  {{ item.name | capitalize }}
                </h4>
                <more-horizontal-icon
                  size="1.5x"
                  class="cursor-point"
                  @click="openItemsModal(index)"
                />

                <div type="flat" class="item-lists" v-if="index == itemsFlag">
                  <p
                    v-if="
                      item.role.toUpperCase() === 'superAdmin'.toUpperCase() ||
                      item.role.toUpperCase() === 'org owner'.toUpperCase()
                    "
                    @click="openEditPopup(item)"
                    class="green"
                  >
                    Edit
                  </p>
                  <p v-else @click="openDeletePopup(item)" class="red">
                    Remove
                  </p>
                </div>
              </div>
              <div class="flex org-detail">
                <p>Your Role: {{ item.role | capitalize }}</p>
                <!--<p>ABN: {{ item.abn }}</p>-->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <vs-popup
      class="holamundo"
      :title="`Edit - ${editData.name}` "
      :active.sync="editPopupActive"
    >
      <vs-input
        :success="!errors.first('basic.name') && editData.name != ''"
        val-icon-success="done"
        val-icon-danger="error"
        :danger="errors.first('basic.name') ? true : false"
        v-validate="'required'"
        name="name"
        data-vv-as="name"
        label="Workspace Name"
        class="w-full"
        data-vv-scope="basic"
        :danger-text="errors.first('basic.name')"
        v-model="editData.name"
      />
      <vs-input
        v-if="showABN"
        :success="!errors.first('basic.abn') && editData.abn != ''"
        val-icon-success="done"
        val-icon-danger="error"
        :danger="errors.first('basic.abn') ? true : false"
        v-validate="'required'"
        name="abn"
        :danger-text="errors.first('basic.abn')"
        data-vv-as="ABN"
        label="ABN"
        class="w-full"
        data-vv-scope="basic"
        v-model="editData.abn"
      />
    </vs-popup>
    <vs-popup
      class="holamundo remove-popup-title"
      title=""
      :active.sync="deletePopupActive"
    >
      <div>
        <div class="flex justify-center slash-icon">
          <slash-icon size="4x" class="custom-class text-center" />
        </div>
        <p class="remove-text p-3 text-center">Remove</p>
        <h5 class="my-2 remove-title text-center">
          Are you sure you want to remove this Workspaces from your account?
        </h5>
        <p class="my-2 remove-confirm">
          This cannot be undone. You will need to contact Fresh Clinics to add
          you again.
        </p>
        <vs-card class="remove-card">
          <h4 class="p-3 pt-5 remove-title">
            {{ forDelete.name | capitalize }}
          </h4>
          <div class="flex pl-3 pb-5">
            <p class="pr-10 remove-title">
              Your Role: {{ forDelete.role | capitalize }}
            </p>
            <p class="pr-10 remove-role">ABN: {{ forDelete.abn }}</p>
          </div>
        </vs-card>
        <div class="text-right">
          <vs-button color="dark" class="mr-2" type="border">Cancel</vs-button>
          <vs-button color="danger" type="filled">Remove</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { MoreHorizontalIcon, SlashIcon } from "vue-feather-icons";
export default {
  data() {
    return {
      organizations: [],
      editPopupActive: false,
      deletePopupActive: false,
      editData: {},
      forDelete: {},
      itemsFlag: -1,
    };
  },
  components: {
    MoreHorizontalIcon,
    SlashIcon,
  },
  computed: {
    showAbn: process.env.VUE_APP_REGION !== "US",
  },
  methods: {
    openDeletePopup(org) {
      this.forDelete = org;
      this.deletePopupActive = true;
    },
    openEditPopup(org) {
      this.editData = org;
      this.editPopupActive = true;
    },
    openItemsModal(number) {
      this.itemsFlag = number;
    },
    orgChanged(org) {
      let url = `/${org.role.toLowerCase().replaceAll(" ", "-")}`;
      window.open(url, "_blank");
      // console.log(url);
    },
  },
  created() {
    const user = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    this.activeOrganizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    this.activeOrganizationName =
      user.organizations[
        _.findIndex(user.organizations, ["_id", this.activeOrganizationId])
      ].name;
    this.organizations = user.organizations;
    console.log(this.organizations);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/selectOrganization.scss";
</style>
